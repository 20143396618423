import { inject, LOCALE_ID } from '@angular/core';
import { BusinessPlanDataAccessService } from '@joorney/business-plan-shared-frontend-business-plan-data-access';
import { contextActions } from '@joorney/business-plan-shared-frontend-context-store';
import { ComputationPageFeatureService } from '@joorney/computation-jwriter-word-computation-page-feature';
import {
  jwWordMsOfficeContentControlCreateTag,
  jwWordMsOfficeContentControlExtractTagInfos,
  jwWordMsOfficeContentControlGetAll,
  jwWordMsOfficeContentControlGetAllUpdatable,
  jwWordMsOfficeContentControlGetById,
  jwWordMsOfficeContentControlIsAJwwControl,
  jwWordMsOfficeContentControlSetEditable,
  jwWordMsOfficeContentControlUpdate,
  jwWordMsOfficeContentControlUpdateStyle
} from '@joorney/ms-office-jwriter-word-office-api-data-access';
import { LastBusinessPlanVersionDTO } from '@joorney/shell-shared-jwriter-core-api-data-access';
import { concatTap } from '@joorney/utils-shared-rxjs-utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { createSelector, Store } from '@ngrx/store';
import { concat, concatMap, filter, from, map, switchMap, tap, toArray } from 'rxjs';
import { dataSyncActions } from './data-sync.actions';
import { ContentControl } from './data-sync.reducer';
import { $syncDataContentControlsAreFromSelectedBp, $syncDataEditableContentControls, $syncDataGetEditableContentControlById } from './data-sync.selectors';

export const init$ = createEffect(
  (action$ = inject(Actions)) => {
    return action$.pipe(
      ofType(dataSyncActions.dataSyncInitialization),
      concatMap(({ contentControlIds }) => concat(...contentControlIds.map((id) => jwWordMsOfficeContentControlGetById(id))).pipe(toArray())),
      map((contentControls) => dataSyncActions.dataSyncLoaded({ contentControls })),
    );
  },
  { functional: true },
);

export const contentControlAdded$ = createEffect(
  (action$ = inject(Actions)) => {
    return action$.pipe(
      ofType(dataSyncActions.contentControlAdded),
      concatMap(({ contentControlId }) => jwWordMsOfficeContentControlGetById(contentControlId)),
      map((contentControl) => dataSyncActions.contentControlLoaded({ contentControl })),
    );
  },
  { functional: true },
);

export const editableToggled$ = createEffect(
  (action$ = inject(Actions)) => {
    return action$.pipe(
      ofType(dataSyncActions.editableToggled),
      concatMap(({ editable }) => from(jwWordMsOfficeContentControlGetAll()).pipe(map((contentControls) => ({ editable, contentControls })))),
      filter(({ contentControls }) => contentControls.length > 0),
      concatTap(({ editable, contentControls }) => concat(...contentControls.map(({ id }) => jwWordMsOfficeContentControlSetEditable(id, editable)))),
      map((data) => ({ ...data, contentControls: data.contentControls.filter(({ tag }) => jwWordMsOfficeContentControlIsAJwwControl(tag)) })),
      map(({ editable, contentControls }) => dataSyncActions.dataSyncLoaded({ contentControls: contentControls.map((cc) => ({ ...cc, cannotEdit: !editable })) })),
    );
  },
  { functional: true },
);

export const refreshData$ = createEffect(
  (
    action$ = inject(Actions),
    store = inject(Store),
    businessPlanDataAccessService = inject(BusinessPlanDataAccessService),
    computationPageFeatureService = inject(ComputationPageFeatureService),
    locale = inject(LOCALE_ID),
  ) => {
    return action$.pipe(
      ofType(dataSyncActions.refreshDataClicked),
      concatLatestFrom(({ contentControlId }) => store.select($syncDataGetEditableContentControlById(contentControlId))),
      map(([, cc]) => cc),
      filter((cc): cc is ContentControl => cc !== undefined),
      concatMap(({ id, tag }) => {
        const { bpId, tableTag, yearIndex } = jwWordMsOfficeContentControlExtractTagInfos(tag);
        return businessPlanDataAccessService.getBusinessPlanById(bpId).pipe(
          switchMap((bp) => computationPageFeatureService.generateInsertContents(bp, tableTag, yearIndex)),
          tap((contents) => void jwWordMsOfficeContentControlUpdate(id, contents, locale, tag)),
        );
      }),
    );
  },
  { functional: true, dispatch: false },
);

const updateContentControls$ = (
  contentControls: ContentControl[],
  bp: LastBusinessPlanVersionDTO,
  computationPageFeatureService: ComputationPageFeatureService,
  locale: string,
) => {
  const contentControls$ = contentControls.map((cc) => {
    const { tableTag, yearIndex } = jwWordMsOfficeContentControlExtractTagInfos(cc.tag);
    const contentControlNewTag = jwWordMsOfficeContentControlCreateTag(bp.id, tableTag, yearIndex);
    return computationPageFeatureService
      .generateInsertContents(bp, tableTag, yearIndex)
      .pipe(switchMap((contents) => from(jwWordMsOfficeContentControlUpdate(cc.id, contents, locale, contentControlNewTag))));
  });
  return concat(...contentControls$);
};

export const updateDataToLatestBpVersion$ = createEffect(
  (
    action$ = inject(Actions),
    store = inject(Store),
    businessPlanDataAccessService = inject(BusinessPlanDataAccessService),
    computationPageFeatureService = inject(ComputationPageFeatureService),
    locale = inject(LOCALE_ID),
  ) => {
    return action$.pipe(
      ofType(dataSyncActions.updateToLatestClicked),
      concatLatestFrom(({ contentControlId }) => store.select($syncDataGetEditableContentControlById(contentControlId))),
      map(([, cc]) => cc),
      filter((cc): cc is ContentControl => cc !== undefined),
      concatMap((cc) => {
        const { bpId } = jwWordMsOfficeContentControlExtractTagInfos(cc.tag);
        return businessPlanDataAccessService
          .getLastBusinessPlanVersion(bpId)
          .pipe(
            concatMap((bp) =>
              updateContentControls$([cc], bp, computationPageFeatureService, locale).pipe(map(() => contextActions.initWithBusinessPlanId({ businessPlanId: bp.id }))),
            ),
          );
      }),
    );
  },
  { functional: true },
);

const $updateAllContentControlsData = createSelector({
  allContentControlsFromSelectedBp: $syncDataContentControlsAreFromSelectedBp,
  editableContentControls: $syncDataEditableContentControls,
});

export const updateAllContentControlsToLatestBpVersion$ = createEffect(
  (
    action$ = inject(Actions),
    store = inject(Store),
    businessPlanDataAccessService = inject(BusinessPlanDataAccessService),
    computationPageFeatureService = inject(ComputationPageFeatureService),
    locale = inject(LOCALE_ID),
  ) => {
    return action$.pipe(
      ofType(dataSyncActions.updateAllToLatestClicked),
      concatLatestFrom(() => store.select($updateAllContentControlsData)),
      filter(([, { editableContentControls, allContentControlsFromSelectedBp }]) => editableContentControls.length > 0 && allContentControlsFromSelectedBp),
      // ! Content controls should be fetch as they appear in the document in order to avoid range position errors.
      // ! If we fetch them from the store, they will be sorted by id and might not be the same order as they appear in the document.
      concatMap(() => jwWordMsOfficeContentControlGetAllUpdatable()),
      concatMap((contentControls) => {
        const [{ tag }] = contentControls;
        const { bpId } = jwWordMsOfficeContentControlExtractTagInfos(tag);
        return businessPlanDataAccessService.getLastBusinessPlanVersion(bpId).pipe(map((bp) => ({ bp, contentControls })));
      }),
      concatMap(({ bp, contentControls }) =>
        updateContentControls$(contentControls, bp, computationPageFeatureService, locale).pipe(map(() => contextActions.initWithBusinessPlanId({ businessPlanId: bp.id }))),
      ),
    );
  },
  { functional: true },
);

export const updateAllContentControlsStyle$ = createEffect(
  (action$ = inject(Actions)) => {
    return action$.pipe(
      ofType(dataSyncActions.changeGlobalStyleClicked),
      concatMap(({ styleNumber }) => from(jwWordMsOfficeContentControlGetAll()).pipe(map((contentControls) => ({ contentControls, styleNumber })))),
      concatMap(({ contentControls, styleNumber }) => {
        const contentControls$ = contentControls.map((contentControl) => from(jwWordMsOfficeContentControlUpdateStyle(contentControl.id, styleNumber)));
        return concat(...contentControls$);
      }),
    );
  },
  { functional: true, dispatch: false },
);
