import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { $contextCurrentBusinessPlanId, $contextCurrentBusinessPlanParentId, contextActions } from '@joorney/business-plan-shared-frontend-context-store';
import { dataSyncActions } from '@joorney/data-sync-jwriter-word-data-sync-store';
import {
  jwWordMsOfficeContentControlAttachListener,
  jwWordMsOfficeMetadataGet,
  jwWordMsOfficeMetadataSet,
  MsOfficeDocumentMetadataKey,
} from '@joorney/ms-office-jwriter-word-office-api-data-access';
import { GoogleTagManagerFeatureService } from '@joorney/shell-shared-frontend-google-tag-manager-feature';
import { AuthService } from '@joorney/users-shared-frontend-authentication-feature';
import { ObservableComponentClass } from '@joorney/utils-shared-frontend-ng-component-utils';
import { createSelector, Store } from '@ngrx/store';
import { takeUntil, tap } from 'rxjs';

export const $contextBusinessPlanSave = createSelector({ versionId: $contextCurrentBusinessPlanId, projectId: $contextCurrentBusinessPlanParentId });

@Component({
  selector: 'jw-word-app',
  standalone: true,
  imports: [RouterModule],
  template: '<router-outlet />',
})
export class AppComponent extends ObservableComponentClass implements OnInit {
  constructor(
    private readonly store: Store,
    private readonly authService: AuthService,
    private readonly googleTagManagerFeatureService: GoogleTagManagerFeatureService,
  ) {
    super();
    if (Office?.context?.document !== undefined) {
      void jwWordMsOfficeContentControlAttachListener(
        this.onInitialized.bind(this),
        this.onAdded.bind(this),
        this.onDeleted.bind(this),
        this.onEntered.bind(this),
        this.onExited.bind(this),
      );
      this.initContext();
    }
  }

  ngOnInit(): void {
    this.saveContext();
    this.authService.startUp().pipe(takeUntil(this.destroyed$)).subscribe();
    this.googleTagManagerFeatureService.appendGoogleTagManagerScript();
  }

  private onInitialized(contentControlIds: number[]) {
    this.store.dispatch(dataSyncActions.dataSyncInitialization({ contentControlIds }));
  }

  private onAdded(ids: number[]) {
    this.store.dispatch(dataSyncActions.contentControlAdded({ contentControlId: ids[0] }));
  }

  private onDeleted(ids: number[]) {
    this.store.dispatch(dataSyncActions.contentControlDeleted({ contentControlId: ids[0] }));
  }

  private onExited(ids: number[]) {
    this.store.dispatch(dataSyncActions.contentControlExited({ contentControlId: ids[0] }));
  }

  private onEntered(ids: number[]) {
    this.store.dispatch(dataSyncActions.contentControlEntered({ contentControlId: ids[0] }));
  }

  private initContext() {
    const businessPlanId = jwWordMsOfficeMetadataGet<number>(MsOfficeDocumentMetadataKey.BusinessPlanVersionId);
    if (businessPlanId !== null) {
      this.store.dispatch(contextActions.initWithBusinessPlanId({ businessPlanId }));
    }
  }

  private saveContext() {
    this.store
      .select($contextBusinessPlanSave)
      .pipe(
        tap(({ projectId, versionId }) => {
          jwWordMsOfficeMetadataSet(MsOfficeDocumentMetadataKey.BusinessPlanProjectId, projectId);
          jwWordMsOfficeMetadataSet(MsOfficeDocumentMetadataKey.BusinessPlanVersionId, versionId);
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }
}
