import { inject } from '@angular/core';
import { $contextBusinessPlanVisaTypeCountryName, $contextCurrentBusinessPlanName } from '@joorney/business-plan-shared-frontend-context-store';
import { WEBSITE_STATUS_WITH_URL } from '@joorney/marketing-jwriter-word-marketing-section-domain';
import { jwWordMsOfficeContentControlInsert } from '@joorney/ms-office-jwriter-word-office-api-data-access';
import { toastActions } from '@joorney/shell-shared-frontend-toast-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store, createSelector } from '@ngrx/store';
import { map, switchMap } from 'rxjs';
import { marketingSectionActions } from './marketing-section.actions';
import {
  generateEngagedInEventsText,
  generateNoEngagedInEventsText,
  generateOtherMarketingStrategiesText,
  generateReviewPlatformsText,
  generateSocialMediaText,
  generateWebsiteWithNoUrlText,
  generateWebsiteWithUrlText,
} from './marketing-section.utils';

const MARKETING_TAG = 'Marketing';

const $bpNameAndCountry = createSelector({
  bpName: $contextCurrentBusinessPlanName,
  bpCountryName: $contextBusinessPlanVisaTypeCountryName,
});

export const insertParagraphs$ = createEffect(
  (action$ = inject(Actions)) => {
    return action$.pipe(
      ofType(marketingSectionActions.marketingSectionParagraphsGenerated),
      // MARKETING_TAG tag is used to differentiate Marketing section content controls from the bp ones
      switchMap(({ content }) =>
        jwWordMsOfficeContentControlInsert(
          MARKETING_TAG,
          content.map((data) => ({ ...data, textAlignment: 'Justified' })),
          null,
        ),
      ),
    );
  },
  { functional: true, dispatch: false },
);

export const generateSocialMediaParagraphs$ = createEffect(
  (action$ = inject(Actions), store = inject(Store)) => {
    return action$.pipe(
      ofType(marketingSectionActions.marketingSectionInsertSocialMediaClicked),
      concatLatestFrom(() => store.select($contextCurrentBusinessPlanName)),
      map(([{ socialMedia }, bpName]) => {
        if (bpName === undefined) {
          const messageText = 'In order to insert Social Media text you must select a business plan first';
          return toastActions.displayWarningMessage({ messageText, options: { manualDismiss: true } });
        }
        const paragraphTexts = generateSocialMediaText(bpName, socialMedia);
        return marketingSectionActions.marketingSectionParagraphsGenerated({ content: paragraphTexts.map((paragraphText) => ({ type: 'text', paragraphText })) });
      }),
    );
  },
  { functional: true },
);

export const generateEventsParagraphs$ = createEffect(
  (action$ = inject(Actions), store = inject(Store)) => {
    return action$.pipe(
      ofType(marketingSectionActions.marketingSectionInsertEventsClicked),
      concatLatestFrom(() => store.select($bpNameAndCountry)),
      map(([{ isEngaged, events }, { bpName, bpCountryName }]) => {
        if (bpName === undefined || bpCountryName === undefined) {
          const messageText = 'In order to insert Events text you must select a business plan first';
          return toastActions.displayWarningMessage({ messageText, options: { manualDismiss: true } });
        }
        const paragraphText = isEngaged ? generateEngagedInEventsText(bpName, bpCountryName, events) : generateNoEngagedInEventsText(bpName, bpCountryName);
        return marketingSectionActions.marketingSectionParagraphsGenerated({ content: [{ type: 'text', paragraphText }] });
      }),
    );
  },
  { functional: true },
);

export const generateWebsiteParagraphs$ = createEffect(
  (action$ = inject(Actions), store = inject(Store)) => {
    return action$.pipe(
      ofType(marketingSectionActions.marketingSectionInsertWebsiteClicked),
      concatLatestFrom(() => store.select($contextCurrentBusinessPlanName)),
      map(([{ websiteStatus, websiteUrl }, bpName]) => {
        if (bpName === undefined) {
          const messageText = 'In order to insert Website text you must select a business plan first';
          return toastActions.displayWarningMessage({ messageText, options: { manualDismiss: true } });
        }
        const paragraphs = WEBSITE_STATUS_WITH_URL.includes(websiteStatus) ? generateWebsiteWithUrlText(bpName, websiteStatus, websiteUrl) : generateWebsiteWithNoUrlText(bpName);
        return marketingSectionActions.marketingSectionParagraphsGenerated({
          content: paragraphs.map(({ paragraphText, footNotes }) => ({ type: 'text', paragraphText, footNotes })),
        });
      }),
    );
  },
  { functional: true },
);

export const generateReviewPlatformsParagraphs$ = createEffect(
  (action$ = inject(Actions), store = inject(Store)) => {
    return action$.pipe(
      ofType(marketingSectionActions.marketingSectionInsertReviewPlatformsClicked),
      concatLatestFrom(() => store.select($contextCurrentBusinessPlanName)),
      map(([{ reviewPlatforms }, bpName]) => {
        if (bpName === undefined) {
          const messageText = 'In order to insert Review Platforms text you must select a business plan first';
          return toastActions.displayWarningMessage({ messageText, options: { manualDismiss: true } });
        }
        const { paragraphText, footNotes } = generateReviewPlatformsText(bpName, reviewPlatforms);
        return marketingSectionActions.marketingSectionParagraphsGenerated({ content: [{ type: 'text', paragraphText, footNotes }] });
      }),
    );
  },
  { functional: true },
);

export const generateOtherMarketingStrategyParagraphs$ = createEffect(
  (action$ = inject(Actions), store = inject(Store)) => {
    return action$.pipe(
      ofType(marketingSectionActions.marketingSectionInsertOtherMarketingStrategiesClicked),
      concatLatestFrom(() => store.select($contextCurrentBusinessPlanName)),
      map(([{ otherStrategies }, bpName]) => {
        if (bpName === undefined) {
          const messageText = 'In order to insert Other Marketing Strategies text you must select a business plan first';
          return toastActions.displayWarningMessage({ messageText, options: { manualDismiss: true } });
        }
        const paragraphs = generateOtherMarketingStrategiesText(bpName, otherStrategies);
        return marketingSectionActions.marketingSectionParagraphsGenerated({
          content: paragraphs.map(({ paragraphText, footNotes }) => ({ type: 'text', paragraphText, footNotes })),
        });
      }),
    );
  },
  { functional: true },
);
