import { GeneratedContent } from '@joorney/computation-shared-frontend-generated-computed-data-data-access';
import { GeneratedContentData, JwTableRowClass } from '@joorney/computation-shared-frontend-sections-domain';

type FilterableGeneratedContentData = GeneratedContentData & { isDisplayed: boolean };

const areValuesEqualOrIncreasing = (values: number[]): boolean => values.every((value, index) => index === 0 || value >= values[index - 1]);

export const transformProfitAndLossData = (
  data: GeneratedContent,
  yearLabels: string[],
  filterZeroValues = true,
  hideDecreasingNetProfitSalesRatio = true,
): GeneratedContentData[] => {
  const datasource: FilterableGeneratedContentData[] = [];
  // Total Sales section
  const salesValues = (data.salesForecast.total?.year ?? []).map((y) => y.totalSales);
  datasource.push({
    label: 'Sales',
    values: salesValues,
    isHeading: true,
    styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableDivider],
    isCurrency: true,
    noDigits: true,
    isDisplayed: filterZeroValues ? salesValues.some((v) => v !== 0) : true,
  });
  const subtotalDirectCostOfSalesValues = (data.salesForecast.total?.year ?? []).map((y) => y.subtotalDirectCostOfSales);
  datasource.push({
    label: 'Direct Cost of Sales',
    values: subtotalDirectCostOfSalesValues,
    isHeading: false,
    isCurrency: true,
    noDigits: true,
    isDisplayed: filterZeroValues ? subtotalDirectCostOfSalesValues.some((v) => v !== 0) : true,
  });
  datasource.push({
    label: 'Total Cost of Sales',
    values: subtotalDirectCostOfSalesValues,
    isHeading: false,
    styleClass: [JwTableRowClass.TableHeading],
    isCurrency: true,
    noDigits: true,
    isDisplayed: filterZeroValues ? subtotalDirectCostOfSalesValues.some((v) => v !== 0) : true,
  });
  const grossMarginValues = (data.salesForecast.total?.year ?? []).map((y) => y.totalGrossMargin);
  datasource.push({
    label: 'Gross Margin',
    values: grossMarginValues,
    isHeading: false,
    isCurrency: true,
    noDigits: true,
    isDisplayed: filterZeroValues ? grossMarginValues.some((v) => v !== 0) : true,
  });
  const grossMarginRateValues = (data.salesForecast.total?.year ?? []).map((y) => (y.totalGrossMarginRate ?? 0) * 100);
  datasource.push({
    label: 'Gross Margin %',
    values: grossMarginRateValues,
    isHeading: false,
    isPercentage: true,
    isDisplayed: filterZeroValues ? grossMarginRateValues.some((v) => v !== 0) : true,
  });
  // OPEX section
  datasource.push({
    label: 'Operating Expenses',
    values: Array<null>(yearLabels.length).fill(null),
    isHeading: true,
    styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableDivider],
    isCurrency: true,
    noDigits: true,
    isDisplayed: true,
  });
  const payrollValues = (data.personnel.total?.year ?? []).map((y) => y.totalPayrollExpenses);
  datasource.push({
    label: 'Payroll',
    values: payrollValues,
    isHeading: false,
    isCurrency: true,
    noDigits: true,
    isDisplayed: filterZeroValues ? payrollValues.some((v) => v !== 0) : true,
  });
  const payrollTaxesValues = (data.personnel.total?.year ?? []).map((y) => y.totalPayrollTaxes);
  datasource.push({
    label: 'Payroll Taxes',
    values: payrollTaxesValues,
    isHeading: false,
    isCurrency: true,
    noDigits: true,
    isDisplayed: filterZeroValues ? payrollTaxesValues.some((v) => v !== 0) : true,
  });
  datasource.push(
    ...(data.expensesDetail.expenses?.expense?.map((e) => {
      const expenseValues = (e.year ?? []).map((y) => y.amount);
      return {
        label: e['@name'],
        values: expenseValues,
        isHeading: false,
        isCurrency: true,
        noDigits: true,
        isDisplayed: filterZeroValues ? expenseValues.some((v) => v !== 0) : true,
      };
    }) ?? []),
  );
  const fundingsAndInvestmentsYearsValues = data.fundingsAndInvestments.year
    .filter((y) => y['@n'] !== 0) // 0 is for startup-funding, the year values start from 1
    .map((y) => y.investments.totalDepreciation || 0);
  datasource.push({
    label: 'Depreciation',
    values: fundingsAndInvestmentsYearsValues,
    isHeading: false,
    isCurrency: true,
    noDigits: true,
    isDisplayed: fundingsAndInvestmentsYearsValues.some((v) => v !== 0),
  });
  // Total OPEX section
  const totalOperatingExpensesValues = (data.expensesDetail.total?.year ?? []).map((y) => y.totalAmount);
  datasource.push({
    label: 'Total Operating Expenses',
    values: totalOperatingExpensesValues,
    isHeading: true,
    styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableDivider],
    isCurrency: true,
    noDigits: true,
    isDisplayed: filterZeroValues ? totalOperatingExpensesValues.some((v) => v !== 0) : true,
  });
  // Total OPEX section
  const profitBeforeInterestAndTaxesValues = (data.profitAndLoss.year ?? []).map((y) => y.profitBeforeInterestAndTaxes);
  datasource.push({
    label: 'Profit Before Interest and Taxes',
    values: profitBeforeInterestAndTaxesValues,
    isHeading: false,
    isCurrency: true,
    noDigits: true,
    isDisplayed: filterZeroValues ? profitBeforeInterestAndTaxesValues.some((v) => v !== 0) : true,
  });
  const interestIncurredValues = (data.profitAndLoss.year ?? []).map((y) => y.interestIncurred);
  datasource.push({
    label: 'Interest Incurred',
    values: interestIncurredValues,
    isHeading: false,
    isCurrency: true,
    noDigits: true,
    isDisplayed: filterZeroValues ? interestIncurredValues.some((v) => v !== 0) : true,
  });
  const ebitdaValues = (data.profitAndLoss.year ?? []).map((y) => y.ebitda);
  datasource.push({
    label: 'EBITDA',
    values: ebitdaValues,
    isHeading: false,
    isCurrency: true,
    noDigits: true,
    isDisplayed: filterZeroValues ? ebitdaValues.some((v) => v !== 0) : true,
  });
  const taxesIncurredValues = (data.profitAndLoss.year ?? []).map((y) => y.taxesIncurred);
  datasource.push({
    label: 'Taxes Incurred',
    values: taxesIncurredValues,
    isHeading: false,
    isCurrency: true,
    noDigits: true,
    isDisplayed: filterZeroValues ? taxesIncurredValues.some((v) => v !== 0) : true,
  });
  // “Net Profit” and “Net Profit/Sales” section
  const netProfitValues = (data.profitAndLoss.year ?? []).map((y) => y.netProfit);
  datasource.push({
    label: 'Net Profit',
    values: netProfitValues,
    isHeading: true,
    styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableDivider],
    isCurrency: true,
    noDigits: true,
    isDisplayed: filterZeroValues ? netProfitValues.every((v) => v !== undefined) && netProfitValues.some((v) => v !== 0) : true,
  });
  const netProfitSalesRatios = (data.profitAndLoss.year ?? []).map((y) => y.netProfitSalesRatio);
  const netProfitSalesRatioValues = netProfitSalesRatios.map((v) => (v ?? 0) * 100);
  const isRatioValuesEqualOrIncreasing = areValuesEqualOrIncreasing(netProfitSalesRatioValues);
  const hasNoZeroValues = netProfitSalesRatios.every((v) => v !== undefined) && netProfitSalesRatioValues.some((v) => v !== 0);
  datasource.push({
    label: 'Net Profit/Sales',
    values: netProfitSalesRatioValues,
    isHeading: false,
    styleClass: [JwTableRowClass.TableHeading],
    isPercentage: true,
    isDisplayed: hideDecreasingNetProfitSalesRatio && !isRatioValuesEqualOrIncreasing ? false : filterZeroValues ? hasNoZeroValues : true,
  });

  return datasource.filter(({ isDisplayed }) => isDisplayed);
};

export const transformProfitAndLossChartData = (data: GeneratedContent, yearLabels: string[]) => {
  const generatedContentDatas = transformProfitAndLossData(data, yearLabels);
  return {
    JW_DATA_NET_PROFIT: generatedContentDatas.find(({ label }) => label === 'Net Profit')?.values as number[],
    JW_DATA_GROSS_MARGIN: generatedContentDatas.find(({ label }) => label === 'Gross Margin')?.values as number[],
    JW_DATA_SALES: generatedContentDatas.find(({ label }) => label === 'Sales')?.values as number[],
  };
};
