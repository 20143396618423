import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { BusinessPlanProjectDataAccessService } from '@joorney/business-plan-shared-frontend-business-plan-project-data-access';
import { environment } from '@joorney/shell-jwriter-word-environment-data-access';
import { BuinessPlanProjectListItems, BusinessPlanProjectByIdDTO } from '@joorney/shell-shared-jwriter-core-api-data-access';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { BehaviorSubject, debounceTime, filter, map, switchMap, tap } from 'rxjs';

const FRONTEND_URL = environment.jwriterFinancialFrontEnd;
const MIN_QUERY_LENGTH = 4;
const RESULT_LIMIT = 10;
const isSearchTermValid = (searchTerm: string, isLimitIncluded = true) => (isLimitIncluded ? searchTerm.length >= MIN_QUERY_LENGTH : searchTerm.length > MIN_QUERY_LENGTH);
export const REFRESH_BP_PROJECT_BY_ID = new BehaviorSubject<{ id?: number }>({});

export type BusinessPlanProjectSearchInput = BuinessPlanProjectListItems | BusinessPlanProjectByIdDTO;

@Component({
  selector: 'jw-search-input-feature',
  templateUrl: './search-input-feature.component.html',
  styleUrls: ['./search-input-feature.component.scss'],
  imports: [ReactiveFormsModule, OverlayPanelModule, InputTextModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SearchInputFeatureComponent,
    },
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputFeatureComponent implements ControlValueAccessor {
  @ViewChild('op', { static: true }) op!: OverlayPanel;
  @ViewChild('searchInput', { static: true }) searchInput!: ElementRef;

  value: BusinessPlanProjectSearchInput | null = null;
  touched = false;
  disabled = false;

  businessPlanProjects: BusinessPlanProjectSearchInput[] = [];
  loadingSuggestions = false;
  JWFProjectUrl = '';

  readonly formGroup = new FormGroup({
    searchTerm: new FormControl<string | null>(null, [Validators.required]),
  });

  private get searchTermValue() {
    return this.formGroup.controls.searchTerm.value || '';
  }

  constructor(
    private businessPlanProjectDataAccessService: BusinessPlanProjectDataAccessService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.formGroup.controls.searchTerm.valueChanges
      .pipe(
        debounceTime(400),
        map((term) => term?.trim() || ''),
        filter((term) => isSearchTermValid(term)),
        tap(() => {
          this.loadingSuggestions = true;
          this.changeDetectorRef.markForCheck();
        }),
        switchMap((term) => this.businessPlanProjectDataAccessService.getBusinessPlanProjectList({ searchTerm: term, itemPerPage: RESULT_LIMIT })),
        map((data) => data.items || []),
        tap((data) => {
          this.businessPlanProjects = data;
          this.loadingSuggestions = false;
          this.op.show(null, this.searchInput.nativeElement);
          this.changeDetectorRef.markForCheck();
        }),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-empty-function
  onChange: Function = () => {};

  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-empty-function
  onTouch: Function = () => {};

  // this method sets the value programmatically
  writeValue(value: BusinessPlanProjectSearchInput | null) {
    value !== null && this.selectBPProject(value);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnChange(onChange: Function) {
    this.onChange = onChange;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnTouched(onTouch: Function) {
    this.onTouch = onTouch;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  searchInputOnClick() {
    if (!isSearchTermValid(this.searchTermValue)) {
      return;
    }
    this.op.show(null, this.searchInput.nativeElement);
  }

  searchInputOnBlur() {
    if (this.disabled || this.value?.name === this.searchTermValue) {
      return;
    }
    this.formGroup.controls.searchTerm.setValue(null);
    this.value = null;
    this.onChange(null);
    this.onTouch();
  }

  selectBPProject(bpProject: BusinessPlanProjectSearchInput, refresh = false) {
    if (bpProject.id !== this.value?.id || refresh) {
      this.value = bpProject;
      this.onChange(this.value);
      this.formGroup.controls.searchTerm.setValue(bpProject?.lastBPCompanyName || '', { emitEvent: false });
      this.JWFProjectUrl = `${FRONTEND_URL}/bp/${bpProject.id}`;
      this.businessPlanProjects = [bpProject];
      if (refresh) {
        REFRESH_BP_PROJECT_BY_ID.next({ id: bpProject.id });
      }
    }
    this.op.hide();
  }

  refreshBPProject() {
    const bpId = this.value?.id;
    if (bpId === undefined) {
      return;
    }
    this.loadingSuggestions = true;
    this.businessPlanProjectDataAccessService
      .getBusinessPlanProjectById(bpId)
      .pipe(
        tap((bp) => {
          this.loadingSuggestions = false;
          this.selectBPProject(bp, true);
          this.changeDetectorRef.markForCheck();
        }),
      )
      .subscribe();
  }

  onKeyDown(key: string) {
    if (key === 'Escape' && this.op.overlayVisible) {
      this.op.hide();
    }
    if (!isSearchTermValid(this.searchTermValue, false) && this.op.overlayVisible) {
      this.op.hide();
    }
  }
}
