<div
  class="sidenav"
  [class.opened]="isOpened()"
  [class.expanded]="isExpanded()"
>
  <img
    class="collapse-icon cursor-pointer"
    [src]="expandLogo()"
    (click)="toggleExpand()"
    alt="stack-collapse"
  />
  <div class="stack-header">
    <p-button
      icon="pi pi-times"
      [rounded]="true"
      [text]="true"
      severity="secondary"
      (click)="close()"
    />
  </div>
  <div class="stack-content">
    <ng-container #container />
  </div>
</div>
