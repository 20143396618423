import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ContentControl } from './data-sync.reducer';

export const DATA_SYNC_FEATURE_KEY = 'data-sync';

export const dataSyncActions = createActionGroup({
  source: DATA_SYNC_FEATURE_KEY,
  events: {
    dataSyncInitialization: props<{ contentControlIds: number[] }>(),
    dataSyncLoaded: props<{ contentControls: ContentControl[] }>(),
    contentControlAdded: props<{ contentControlId: number }>(),
    contentControlLoaded: props<{ contentControl: ContentControl }>(),
    contentControlDeleted: props<{ contentControlId: number }>(),
    contentControlEntered: props<{ contentControlId: number }>(),
    contentControlExited: props<{ contentControlId: number }>(),
    editableToggled: props<{ editable: boolean }>(),
    refreshDataClicked: props<{ contentControlId: number }>(),
    updateToLatestClicked: props<{ contentControlId: number }>(),
    updateAllToLatestClicked: emptyProps(),
    changeGlobalStyleClicked: props<{ styleNumber: number }>(),
  },
});
