import { GeneratedContentPersonnel } from '@joorney/computation-shared-frontend-sections-domain';
import { noDigitsCurrencyTransform } from '@joorney/utils-shared-frontend-ng-value-format-utils';
import { enumerateValuesToString, getArticle } from '@joorney/utils-shared-ts-utils';

const formatWordsWithArticles = (words: string[]) => enumerateValuesToString(words.map((w) => `${getArticle(w)} ${w}`));

const formatPositionsWithNumbers = (positions: { name: string; nEmployees: number }[]) => enumerateValuesToString(positions.map(({ name, nEmployees }) => `${nEmployees} ${name}`));

export const generateExecutiveSummaryText = (
  personnelData: GeneratedContentPersonnel,
  totalSales: number[],
  outsourcingExpenseNames: string[],
  firstYearPositionsWithEmployees: { name: string; nEmployees: number }[],
  companyName: string,
  yearLabels: string[],
  locale: string,
) => {
  const firstYearPosition = 0;
  const lastYearPosition = yearLabels.length - 1;

  const firstYear = yearLabels[firstYearPosition];
  const lastYear = yearLabels[lastYearPosition];

  const lastYearEmployees = personnelData.total?.year?.[lastYearPosition].totalNumberOfEmployees ?? 0;

  const firstYearPayrollExpenses = personnelData.total?.year?.[firstYearPosition].totalPayrollExpenses ?? 0;
  const lastYearPayrollExpenses = personnelData.total?.year?.[lastYearPosition].totalPayrollExpenses ?? 0;
  const firstYearPayrollExpensesFormatted = noDigitsCurrencyTransform(firstYearPayrollExpenses, locale);
  const lastYearPayrollExpensesFormatted = noDigitsCurrencyTransform(lastYearPayrollExpenses, locale);

  const firstYearTotalSales = totalSales[firstYearPosition] ?? 0;
  const lastYearTotalSales = totalSales[lastYearPosition] ?? 0;
  const firstYearTotalSalesFormatted = noDigitsCurrencyTransform(firstYearTotalSales, locale);
  const lastYearTotalSalesFormatted = noDigitsCurrencyTransform(lastYearTotalSales, locale);

  const firstYearPositionsText = formatPositionsWithNumbers(firstYearPositionsWithEmployees);
  const outsourcingExpensesText = formatWordsWithArticles(outsourcingExpenseNames);

  return [
    `In addition to the Applicant, ${companyName} will hire ${firstYearPositionsText} in ${firstYear}. By the end of ${lastYear}, the Company will have hired a total of ${lastYearEmployees} in-house employees, with payroll expenses rising from ${firstYearPayrollExpensesFormatted} in ${firstYear} to ${lastYearPayrollExpensesFormatted} in ${lastYear}. In addition to the in-house employees, the Company will use the services of independent contractors, including ${outsourcingExpensesText}.`,
    `${companyName} will set and measure the success of its operations by analyzing various key performance indicators (KPIs), such as sales revenue, net profit percentage, and direct cost-to-sales ratio (gross margin). ${companyName} total sales revenue is expected to grow from ${firstYearTotalSalesFormatted} in ${firstYear} to ${lastYearTotalSalesFormatted} in ${lastYear}.`,
  ];
};
