import { formatNumber } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { BusinessPlanProjectDataAccessService } from '@joorney/business-plan-shared-frontend-business-plan-project-data-access';
import { $contextCurrentBusinessPlanYearLabels } from '@joorney/business-plan-shared-frontend-context-store';
import { generateSourceOfFundsText } from '@joorney/computation-jwriter-word-sections-feature';
import { GeneratedComputedDataService } from '@joorney/computation-shared-frontend-generated-computed-data-data-access';
import { GeneratedContentPersonnel } from '@joorney/computation-shared-frontend-sections-domain';
import {
  breakEvenAnalysisTextAfterTable,
  breakEvenAnalysisTextBeforeTable,
  generateConclusionText,
  generateExecutiveSummaryText,
  transformBalanceSheetData,
  transformBreakEvenChartData,
  transformBreakEvenData,
  transformCashFlowData,
  transformCostAndBenefitAnalysisData,
  transformFeasibilityAnalysisData,
  transformPersonnelPlanData,
  transformPersonnelPlanDesignatedSalaryData,
  transformPersonnelPlanNbEmployeeData,
  transformPersonnelSummaryData,
  transformPersonnelSummaryText,
  transformProfitAndLossChartData,
  transformProfitAndLossData,
  transformSalesForecastData,
  transformSourceOfFundsData,
  transformTaxPaidByYearChartData,
  transformUseOfProceedsData,
} from '@joorney/computation-shared-frontend-sections-feature';
import {
  JW_WORD_TABLE_PERSONNEL_SUMMARY_FIRST_COL_WIDTH,
  JwWordAlignment,
  JwWordBreakLineInsertData,
  JwWordChartInsertData,
  JwWordInsertContents,
  JwWordTableInsertData,
  JwWordTextInsertData,
  MSOfficeTaxPaidByYearChartElementsData,
  jwWordMsOfficeContentControlCreateTag,
  jwWordMsOfficeContentControlInsert,
  jwWordMsOfficeHasParentContentControl
} from '@joorney/ms-office-jwriter-word-office-api-data-access';
import { JwWordBusinessPlanTable, JwWordTableColor, getBusinessPlanTables } from '@joorney/ms-office-jwriter-word-sections-domain';
import { OperatingExpensesDataAccessService } from '@joorney/operating-expense-shared-frontend-operating-expenses-data-access';
import { toastActions } from '@joorney/shell-shared-frontend-toast-store';
import {
  BusinessPlanByIdDTO,
  BusinessPlanByProjectDTO,
  BusinessPlanByProjectFeatureConfiguration,
  EmployeeInPositionDTO,
  Enums
} from '@joorney/shell-shared-jwriter-core-api-data-access';
import { noDigitsCurrencyTransform } from '@joorney/utils-shared-frontend-ng-value-format-utils';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Observable, combineLatest, map, merge, of, switchMap, take, toArray } from 'rxjs';
import {
  MANAGING_POSITIONS,
  MANAGING_POSITIONS_EQUIVALENCE,
  Tendency,
  TendencyResult,
  VisaTypeCountryCode,
  applicantPositionText,
  tendenciesCalculationFromValues,
} from './computation-page-feature.utils';

const costAndBenefitAnalysisText = (companyName: string) => {
  return `Net profit and the total expenses ratio is increasing over time, suggesting that the business is becoming efficient and, therefore, more profitable. Hence, it can be concluded that ${companyName} is a lucrative investment.`;
};
const BREAK_LINE: JwWordBreakLineInsertData = { type: 'break-line' };

@Injectable({ providedIn: 'root' })
export class ComputationPageFeatureService {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private businessPlanProjectDataAccessService: BusinessPlanProjectDataAccessService,
    private generatedComputedDataService: GeneratedComputedDataService,
    private operatingExpensesDataAccessService: OperatingExpensesDataAccessService,
    private store: Store,
  ) {}

  private companyImpactEconomyText(
    businessPlan: BusinessPlanByIdDTO,
    yearLabels: string[],
    data: MSOfficeTaxPaidByYearChartElementsData,
    personnel: GeneratedContentPersonnel,
  ): string {
    const { JW_DATA_PAYROLL_TAX, JW_DATA_NET_INCOME_TAX, JW_DATA_TOTAL_TAX } = data;
    const firstYearLabel = yearLabels[0];
    const lastYearLabel = yearLabels[yearLabels.length - 1];
    const firstYearPTaxes = noDigitsCurrencyTransform(JW_DATA_PAYROLL_TAX[0], this.locale);
    const lastYearPTaxes = noDigitsCurrencyTransform(JW_DATA_PAYROLL_TAX[JW_DATA_PAYROLL_TAX.length - 1], this.locale);
    const firstYearNetITaxes = noDigitsCurrencyTransform(JW_DATA_NET_INCOME_TAX[0], this.locale);
    const lastYearNetITaxes = noDigitsCurrencyTransform(JW_DATA_NET_INCOME_TAX[JW_DATA_NET_INCOME_TAX.length - 1], this.locale);
    const firstYearTTaxes = noDigitsCurrencyTransform(JW_DATA_TOTAL_TAX[0], this.locale);
    const lastYearTTaxes = noDigitsCurrencyTransform(JW_DATA_TOTAL_TAX[JW_DATA_TOTAL_TAX.length - 1], this.locale);

    if (businessPlan?.visaType === 'EB2') {
      const numberOfEmployeeInLastYear = personnel.total?.year?.[yearLabels.length - 1].totalNumberOfEmployees ?? 0;
      return `Professionals such as the Petitioner create jobs and act as engines driving economic activity. By the end of Year ${yearLabels.length}, the Company will have hired and trained a total of ${numberOfEmployeeInLastYear} in-house employees, all of whom will be either U.S. citizens or permanent residents, thereby stimulating the U.S. economy both by creating new jobs and increasing the total amount of taxes paid. Over the next five years, the Company expects to make the following tax payments to the U.S. Government, as listed in the graph below:`;
    } else if (businessPlan?.visaTypeCountryCode === VisaTypeCountryCode.USA.toString()) {
      return `The Company will make a positive impact on the U.S. economy. The Company’s net profit and payroll expenses will increase, thus increasing the total taxes paid. The following chart shows the increase in both net income and payroll taxes through the years. Payroll taxes are expected to start at ${firstYearPTaxes} in ${firstYearLabel} and reach ${lastYearPTaxes} in ${lastYearLabel}. Net income taxes are expected to rise from ${firstYearNetITaxes} in ${firstYearLabel}, reaching ${lastYearNetITaxes} in ${lastYearLabel}. The Company is expected to pay ${firstYearTTaxes} in taxes in ${firstYearLabel}, increasing to ${lastYearTTaxes} by the end of ${lastYearLabel}.`;
    } else if (businessPlan?.visaTypeCountryCode === VisaTypeCountryCode.CAN.toString()) {
      return `The Company will make a positive impact on the Canadian economy. The Company’s net profit, as well as payroll expenses will increase, thus increasing total taxes paid. The chart below shows the increase in both net income and payroll taxes through the years. Payroll taxes are expected to start at ${firstYearPTaxes} in ${firstYearLabel} and reach ${lastYearPTaxes} in ${lastYearLabel}. Net Income taxes are expected to rise from ${firstYearNetITaxes} in ${firstYearLabel}, reaching ${lastYearNetITaxes} in ${lastYearLabel}. The Company is expected to pay ${firstYearTTaxes} in taxes in ${firstYearLabel}, increasing to ${lastYearTTaxes} by the end of ${lastYearLabel}.`;
    } else if (businessPlan?.visaTypeCountryCode === VisaTypeCountryCode.NZL.toString()) {
      return `The Company will make a positive impact on New Zealand’s economy. The Company’s net profit, as well as payroll expenses will increase, thus increasing total taxes paid. The chart below shows the increase in both net income and payroll taxes through the years. Payroll taxes are expected to start at ${firstYearPTaxes} in ${firstYearLabel} and reach ${lastYearPTaxes} in ${lastYearLabel}. Net Income taxes are expected to rise from ${firstYearNetITaxes} in ${firstYearLabel}, reaching ${lastYearNetITaxes} in ${lastYearLabel}. The Company is expected to pay ${firstYearTTaxes} in taxes in ${firstYearLabel}, increasing to ${lastYearTTaxes} by the end of ${lastYearLabel}.`;
    } else if (businessPlan?.visaTypeCountryCode === VisaTypeCountryCode.AUS.toString()) {
      return `The Company will make a positive impact on the Australian economy. The Company’s net profit, as well as payroll expenses will increase, thus increasing total taxes paid. The chart below shows the increase in both net income and payroll taxes through the years. Payroll taxes are expected to start at ${firstYearPTaxes} in ${firstYearLabel} and reach ${lastYearPTaxes} in ${lastYearLabel}. Net Income taxes are expected to rise from ${firstYearNetITaxes} in ${firstYearLabel}, reaching ${lastYearNetITaxes} in ${lastYearLabel}. The Company is expected to pay ${firstYearTTaxes} in taxes in ${firstYearLabel}, increasing to ${lastYearTTaxes} by the end of ${lastYearLabel}.`;
    } else {
      // this case is for new visaType that is not registered
      return '';
    }
  }

  private feasibilityAnalysisText = (tendencies: TendencyResult[], yearLabels: string[], firstValue: number): string[] => {
    const firstYear = yearLabels[0];
    const firstValueFormatted = formatNumber(firstValue, this.locale, '1.2-2');
    const fistTemplate = `The Company’s sales will be primarily achieved through the Company’s substantial investment in marketing and sales. The Company expects its total marketing and sales expenses to represent ${firstValueFormatted}% of total revenue in ${firstYear}.`;
    const paragraphs = tendencies.map(({ from, to, tendency, value }) => {
      const initYear = yearLabels[from];
      const finalYear = yearLabels[to];
      const formattedValue = formatNumber(value, this.locale, '1.2-2');

      switch (tendency) {
        case Tendency.Same:
          return `In ${finalYear}, the Company will dedicate the same percentage of total sales toward marketing and sales operations as it did in ${initYear}.`;
        case Tendency.IncreaseContD:
          return `As the Company progresses into ${finalYear}, the Company is expected to continue increasing its investments in marketing and sales operations in order to drive brand recognition and acquire an increasing number of customers. Thus, the Company expects total marketing and sales expenses to represent ${formattedValue}% of total revenue.`;
        case Tendency.DecreaseContD:
          return `As the Company progresses into ${finalYear}, the Company is expected to continue increasing its effectiveness and efficiency in turning dollars spent on marketing and sales into revenue dollars and expects total marketing and sales expenses to represent ${formattedValue}% of total revenue.`;
        case Tendency.Increase:
          return `As the Company progresses into ${finalYear}, its marketing efforts will be intensified and thus the total marketing and sales expenses will represent ${formattedValue}% of the Company’s revenue.`;
        case Tendency.Decrease:
          return `As the Company progresses into ${finalYear}, it is expected to increase its effectiveness and efficiency in turning dollars spent on marketing and sales into revenue dollars. That is why, in ${finalYear}, the Company expects its total marketing and sales expenses to represent a smaller percentage of revenue, at ${formattedValue}%.`;
        default:
          return '';
      }
    });
    return [fistTemplate, ...paragraphs];
  };

  displayProfitAndLossDecreaseWarning({ currYear, prevYear }: { currYear: string; prevYear: string }) {
    const messageText = `The Net Profit/Sales ratio decreased in ${currYear} compared to ${prevYear}. If you want to insert the Cost / Benefit Analysis Table, correct the calculation`;
    this.store.dispatch(toastActions.displayWarningMessage({ messageText, options: { manualDismiss: true } }));
  }

  private displayNoOutsourcingExpensesWarning() {
    const messageText =
      'There is no outsourcing expenses in the current business plan. You need at least one expense marked as "Outsourcing Expense" to insert the Executive Summary Text';
    this.store.dispatch(toastActions.displayWarningMessage({ messageText, options: { manualDismiss: true } }));
  }

  private displayNoFirstYearPositionsWithEmployeesWarning() {
    const messageText =
      'There is no positions with employees in the first year. You need at least one position with employees in the first year to insert the Executive Summary Text';
    this.store.dispatch(toastActions.displayWarningMessage({ messageText, options: { manualDismiss: true } }));
  }

  private displayNoApplicantPositionWarning() {
    const messageText = `There is no applicant assigned to one of the next managing positions: ${MANAGING_POSITIONS.join(', ')}`;
    this.store.dispatch(toastActions.displayWarningMessage({ messageText, options: { manualDismiss: true } }));
  }

  private displayDuplicatedApplicantPositionWarning(positionNames: string[]) {
    const messageText = `There are multiple records with applicants for the ${positionNames.join(', ')} position(s)`;
    this.store.dispatch(toastActions.displayWarningMessage({ messageText, options: { manualDismiss: true } }));
  }

  private displayMultipleApplicantForPositionWarning(positionNames: string[]) {
    const messageText = `There are multiple applicants for the ${positionNames.join(', ')} position(s)`;
    this.store.dispatch(toastActions.displayWarningMessage({ messageText, options: { manualDismiss: true } }));
  }

  generateTablesData(businessPlanId: number) {
    return this.generatedComputedDataService.getTableData(businessPlanId).pipe(map(({ generatedContent, yearLabels }) => ({ generatedContent, yearLabels })));
  }

  personnelPlanPositionsData(businessPlanId: number) {
    return this.generatedComputedDataService.getPersonnelPlanPositionsData(businessPlanId).pipe(
      switchMap(({ data }) => {
        const managingPositions = data.filter(({ data }) => MANAGING_POSITIONS.includes(data.name));
        const obs$ = managingPositions.map(({ data }) =>
          this.generatedComputedDataService
            .getEmployeesData(businessPlanId, data.id)
            .pipe(map(({ employees }) => ({ employees, positionName: data.name, positionValue: MANAGING_POSITIONS_EQUIVALENCE[data.name] }))),
        );
        return merge(...obs$);
      }),
      toArray(),
    );
  }

  isSelectionInContentControl() {
    return jwWordMsOfficeHasParentContentControl();
  }

  insertMsOfficeTableContentControl(businessPlan: BusinessPlanByProjectDTO, tableTag: JwWordBusinessPlanTable, yearIndex: number | null) {
    const contentControlTag = jwWordMsOfficeContentControlCreateTag(businessPlan.id, tableTag, yearIndex);
    return this.generateInsertContents(businessPlan, tableTag, yearIndex).pipe(
      switchMap((contents) => jwWordMsOfficeContentControlInsert(contentControlTag, contents, this.locale)),
    );
  }

  generateInsertContents(
    businessPlan: { featureConfiguration: BusinessPlanByProjectFeatureConfiguration | null; salesForecastType: Enums['BusinessPlanSalesForecastType'] | null; id: number },
    tableTag: JwWordBusinessPlanTable,
    yearIndex: number | null,
  ): Observable<JwWordInsertContents[]> {
    const sections = getBusinessPlanTables(businessPlan.featureConfiguration, businessPlan.salesForecastType);
    const tableTitle = sections.find((section) => section.tag === tableTag)?.name ?? '';
    const businessPlanId = businessPlan.id;

    switch (tableTag) {
      // PERSONNEL PLAN TABLE
      case JwWordBusinessPlanTable.PersonnelPlanNumberOfEmployees:
        return this.generatedComputedDataService.getTableData(businessPlanId).pipe(
          map(({ generatedContent, yearLabels }) => {
            const generatedContentData = transformPersonnelPlanNbEmployeeData(generatedContent.personnel);
            const content: JwWordTableInsertData = { type: 'table', tableTitle, columnTitles: yearLabels, generatedContentData };
            return [content];
          }),
        );

      case JwWordBusinessPlanTable.PersonnelPlanDesignatedSalary:
        return this.generatedComputedDataService.getTableData(businessPlanId).pipe(
          map(({ generatedContent, yearLabels }) => {
            const generatedContentData = transformPersonnelPlanDesignatedSalaryData(generatedContent.personnel);
            return [{ type: 'table', tableTitle, columnTitles: yearLabels, generatedContentData }];
          }),
        );

      case JwWordBusinessPlanTable.PersonnelPlanOrgChart:
        return combineLatest({
          positions: this.generatedComputedDataService.getPersonnelPlanPositionsData(businessPlanId),
          outsourcings: this.operatingExpensesDataAccessService.findAllOutsourcings(businessPlanId),
          yearLabels: this.store.select($contextCurrentBusinessPlanYearLabels).pipe(take(1)),
        }).pipe(
          map(({ positions, outsourcings, yearLabels }) => {
            if (yearIndex === null) {
              return [];
            }
            const positionsNames = positions.data
              .filter(({ data: { years } }) => years?.[yearIndex]?.employeeNumber ?? 0 > 0)
              .map(({ data: { name, namePlural, years } }) => {
                const firstYearEmployeesNumber = years?.[yearIndex].employeeNumber as number; // value validity checked in the filter above
                return `${firstYearEmployeesNumber === 1 ? name : namePlural} [${firstYearEmployeesNumber}]`;
              });
            const outsourcingNames = outsourcings.filter(({ data }) => data[yearIndex]?.value ?? 0 > 0).map(({ name }) => name);

            return [
              { type: 'text', paragraphText: yearLabels[yearIndex], insertLocation: 'Before' },
              { type: 'org-chart', filename: 'OrgChartInline', positionsNames: [...positionsNames, ...outsourcingNames] },
            ];
          }),
        );

      case JwWordBusinessPlanTable.PersonnelPlan:
        return this.generatedComputedDataService.getTableData(businessPlanId).pipe(
          map(({ generatedContent, yearLabels }) => {
            const generatedContentData = transformPersonnelPlanData(generatedContent.personnel);
            return [{ type: 'table', tableTitle, columnTitles: yearLabels, generatedContentData }];
          }),
        );

      case JwWordBusinessPlanTable.PersonnelSummary:
        return this.generatedComputedDataService.getTableData(businessPlanId).pipe(
          map(({ generatedContent, yearLabels }) => {
            const generatedContentData = transformPersonnelSummaryData(generatedContent.personnel, yearLabels);
            const paragraphText = transformPersonnelSummaryText(generatedContent.personnel, yearLabels, this.locale);
            return { generatedContentData, paragraphText };
          }),
          map(({ paragraphText, generatedContentData }) => {
            const text: JwWordTextInsertData = { type: 'text', paragraphText };
            const table: JwWordTableInsertData = {
              type: 'table',
              tableTitle: 'Period',
              columnTitles: ['Hiring Plan'],
              generatedContentData,
              headerRowClass: [],
              tableClass: {
                horizontalAlignment: JwWordAlignment.Left,
                firstColHorizontalAlignment: JwWordAlignment.Centered,
                firstColWidth: JW_WORD_TABLE_PERSONNEL_SUMMARY_FIRST_COL_WIDTH,
                firstColBold: true,
                firstColColor: JwWordTableColor.White,
                firstColBackgroundColor: JwWordTableColor.Gray,
              },
            };
            return [table, BREAK_LINE, text];
          }),
        );

      case JwWordBusinessPlanTable.ApplicantPosition:
        return combineLatest({
          businessPlan: this.generatedComputedDataService.getTableData(businessPlanId).pipe(map(({ businessPlan }) => businessPlan)),
          employeesInPosition: this.personnelPlanPositionsData(businessPlanId),
        }).pipe(
          map(({ businessPlan, employeesInPosition }) => {
            if (!employeesInPosition.length) {
              this.displayNoApplicantPositionWarning();
              return [];
            }
            const managingPositionsWihApplicants = _.reduce(
              employeesInPosition,
              (result, { employees, positionName, positionValue }) => {
                const applicants = employees?.length ? _.filter(employees, (employee) => employee.isApplicant) : [];
                if (applicants.length > 0) {
                  result.push({ positionName, positionValue, applicants });
                }
                return result;
              },
              [] as { positionName: string; positionValue: string; applicants: EmployeeInPositionDTO[] }[],
            );
            if (!managingPositionsWihApplicants.length) {
              this.displayNoApplicantPositionWarning();
              return [];
            }
            const groupByPositionValue = _.groupBy(managingPositionsWihApplicants, ({ positionValue }) => positionValue);
            const duplicatedPositionNames = _.reduce(
              groupByPositionValue,
              (acc, item) => {
                if (item.length > 1) {
                  acc.push(item[0].positionName);
                }
                return acc;
              },
              [] as string[],
            );
            if (duplicatedPositionNames.length) {
              this.displayDuplicatedApplicantPositionWarning(duplicatedPositionNames);
              return [];
            }
            const multipleApplicantsPerPositionNames = _.reduce(
              groupByPositionValue,
              (acc, item) => {
                if (item[0].applicants.length > 1) {
                  acc.push(item[0].positionName);
                }
                return acc;
              },
              [] as string[],
            );
            if (multipleApplicantsPerPositionNames.length) {
              this.displayMultipleApplicantForPositionWarning(multipleApplicantsPerPositionNames);
              return [];
            }
            const applicantPositions = managingPositionsWihApplicants.map(({ positionName, applicants }) => ({ positionName, applicantName: applicants[0].name ?? '' }));
            const paragraphs: JwWordTextInsertData[] = applicantPositionText(businessPlan.name, businessPlan.visaTypeCountryCode as VisaTypeCountryCode, applicantPositions).map(
              (paragraphText) => ({ type: 'text', paragraphText }),
            );
            return paragraphs;
          }),
        );
      // BREAK EVEN ANALYSIS TABLE
      case JwWordBusinessPlanTable.BreakEvenAnalysisTable:
        return this.generatedComputedDataService.getTableData(businessPlanId).pipe(
          map(({ generatedContent, yearLabels }) => {
            const tableData = transformBreakEvenData(generatedContent.breakEven);
            const tableTextBefore = breakEvenAnalysisTextBeforeTable();
            const tableTextAfter = breakEvenAnalysisTextAfterTable(generatedContent, this.locale);
            return { tableData, tableTextBefore, tableTextAfter, yearLabels };
          }),
          map(({ tableTextBefore, tableData, tableTextAfter, yearLabels }) => {
            const contentTextBefore: JwWordTextInsertData = { type: 'text', paragraphText: tableTextBefore };
            const contentTextAfter: JwWordTextInsertData[] = tableTextAfter.map((paragraphText) => ({ type: 'text', paragraphText }));
            const contentData: JwWordTableInsertData = {
              type: 'table',
              tableTitle,
              columnTitles: [yearLabels[0]],
              generatedContentData: tableData,
            };
            return [contentTextBefore, contentData, ...contentTextAfter];
          }),
        );

      case JwWordBusinessPlanTable.BreakEvenAnalysisChart:
        return this.generatedComputedDataService.getTableData(businessPlanId).pipe(
          map(({ generatedContent, yearLabels }) => {
            const { categoriesX, series } = transformBreakEvenChartData(generatedContent.breakEven, this.locale);
            const contentText: JwWordTextInsertData = { type: 'text', paragraphText: '' };
            const content: JwWordChartInsertData = {
              type: 'chart',
              title: `Monthly Break-Even Analysis for ${yearLabels[0]}`,
              filename: 'BreakEvenAnalysisChart',
              yearLabels: categoriesX,
              elements: series,
            };
            // We need to add a text before the chart for keeping track of the content control position when updating it
            // Since chart is slower than text, we need to add the text before the chart
            return [contentText, content];
          }),
        );

      // SALES FORECAST TABLE
      case JwWordBusinessPlanTable.SalesForecast:
        return this.generatedComputedDataService.getTableData(businessPlanId).pipe(
          map(({ generatedContent, businessPlan, yearLabels }) => {
            const generatedContentData = transformSalesForecastData(generatedContent.salesForecast, businessPlan.salesForecastType, yearLabels);
            const contentData: JwWordTableInsertData = {
              type: 'table',
              tableTitle,
              columnTitles: yearLabels,
              generatedContentData,
            };
            return [contentData];
          }),
        );

      // BALANCE SHEET TABLE
      case JwWordBusinessPlanTable.BalanceSheetTable:
        return this.generatedComputedDataService.getTableData(businessPlanId).pipe(
          map(({ generatedContent, yearLabels }) => {
            const generatedContentData = transformBalanceSheetData(generatedContent.balanceSheet, yearLabels);
            const contentData: JwWordTableInsertData = {
              type: 'table',
              tableTitle,
              columnTitles: yearLabels,
              generatedContentData,
            };
            return [contentData];
          }),
        );

      // PROFIT AND LOSS TABLE
      case JwWordBusinessPlanTable.ProfitAndLoss:
        return this.generatedComputedDataService.getTableData(businessPlanId).pipe(
          map(({ generatedContent, yearLabels }) => {
            const generatedContentData = transformProfitAndLossData(generatedContent, yearLabels);
            const contentData: JwWordTableInsertData = {
              type: 'table',
              tableTitle,
              columnTitles: yearLabels,
              generatedContentData,
            };
            return [contentData];
          }),
        );

      case JwWordBusinessPlanTable.ProfitAndLossChart:
        return this.generatedComputedDataService.getTableData(businessPlanId).pipe(
          map(({ generatedContent, yearLabels, businessPlan }) => {
            const elements = transformProfitAndLossChartData(generatedContent, yearLabels);
            const contentText: JwWordTextInsertData = { type: 'text', paragraphText: '' };
            const content: JwWordChartInsertData = {
              type: 'chart',
              title: businessPlan.name,
              filename: 'ProfitAndLossChart_Light',
              yearLabels,
              elements,
            };
            // We need to add a text before the chart for keeping track of the content control position when updating it
            // Since chart is slower than text, we need to add the text before the chart
            return [contentText, content];
          }),
        );

      // CASH FLOW TABLE
      case JwWordBusinessPlanTable.CashFlowTable:
        return this.generatedComputedDataService.getCashFlowTableData(businessPlanId).pipe(
          map(({ generatedContent, yearLabels }) => {
            const generatedContentData = transformCashFlowData(generatedContent, yearLabels);
            const contentData: JwWordTableInsertData = {
              type: 'table',
              tableTitle: 'Year',
              columnTitles: yearLabels,
              generatedContentData,
            };
            return [contentData];
          }),
        );

      // COST BENEFIT ANALYSIS TABLE
      case JwWordBusinessPlanTable.CostBenefitAnalysis:
        return this.generatedComputedDataService.getTableData(businessPlanId).pipe(
          map(({ generatedContent, yearLabels, businessPlan }) => {
            const values = (generatedContent.salesForecast.total?.year ?? []).map((y, i) => Number(generatedContent.profitAndLoss.year?.[i].netProfit) / Number(y.totalSales));
            const tendencies = tendenciesCalculationFromValues(values);
            const firstYearDecreaseTendency = tendencies.filter(({ tendency }) => tendency === Tendency.Decrease || tendency === Tendency.DecreaseContD)?.[0];
            if (firstYearDecreaseTendency !== undefined) {
              const yearsParams = { currYear: yearLabels[firstYearDecreaseTendency.to], prevYear: yearLabels[firstYearDecreaseTendency.from] };
              this.displayProfitAndLossDecreaseWarning(yearsParams);
              return [];
            }

            const paragraphText = costAndBenefitAnalysisText(businessPlan.name);
            const generatedContentData = transformCostAndBenefitAnalysisData(generatedContent.costBenefit, yearLabels);

            const text: JwWordTextInsertData = { type: 'text', paragraphText };
            const table: JwWordTableInsertData = {
              type: 'table',
              tableTitle,
              columnTitles: yearLabels,
              generatedContentData,
            };

            return [table, BREAK_LINE, text];
          }),
        );

      // FEASIBILITY ANALYSIS TABLE
      case JwWordBusinessPlanTable.FeasibilityAnalysis:
        return this.generatedComputedDataService.getTableData(businessPlanId).pipe(
          map(({ generatedContent, yearLabels }) => {
            const generatedContentDatas = transformFeasibilityAnalysisData(generatedContent.feasibility, yearLabels);
            const values = generatedContent.feasibility.totalMarketingAndSalesExpensesAsPercentOfSales.map((t) => t * 100);
            const tendencies = tendenciesCalculationFromValues(values);
            const tableParagraphs = this.feasibilityAnalysisText(tendencies, yearLabels, values[0]);
            return { generatedContentDatas, tableParagraphs, yearLabels };
          }),
          map(({ yearLabels, generatedContentDatas, tableParagraphs }) => {
            const contentText: JwWordTextInsertData[] = tableParagraphs.map((paragraphText) => ({ type: 'text', paragraphText }));
            const contentData: JwWordTableInsertData = {
              type: 'table',
              tableTitle,
              columnTitles: yearLabels,
              generatedContentData: generatedContentDatas,
              insertLocation: 'Before',
            };
            return [contentData, BREAK_LINE, ...contentText];
          }),
        );

      // Company Impact on Economy
      case JwWordBusinessPlanTable.CompaniesImpactOnEconomy:
        return this.generatedComputedDataService.getTableData(businessPlanId).pipe(
          map(({ generatedContent, yearLabels, businessPlan }) => {
            const chartData = transformTaxPaidByYearChartData(generatedContent.profitAndLoss, generatedContent.personnel);
            const paragraphText = this.companyImpactEconomyText(businessPlan, yearLabels, chartData, generatedContent.personnel);
            return { chartData, paragraphText, yearLabels };
          }),
          map(({ paragraphText, chartData, yearLabels }) => {
            const contentText: JwWordTextInsertData = { type: 'text', paragraphText, insertLocation: 'Before' };
            const contentData: JwWordChartInsertData = {
              type: 'chart',
              title: 'Tax Paid by Year',
              filename: 'TaxPaidByYearChart',
              yearLabels,
              elements: chartData,
            };
            return [contentText, BREAK_LINE, contentData];
          }),
        );

      // SOURCE OF FUNDS
      case JwWordBusinessPlanTable.SourceOfFunds:
        return this.generatedComputedDataService.getTableData(businessPlanId).pipe(
          map(({ generatedContent, businessPlan: { name: companyName } }) => {
            const sourceOfFundsText = generateSourceOfFundsText(generatedContent.fundingsAndInvestments, companyName, this.locale);
            const contentText = sourceOfFundsText.map<JwWordTextInsertData>((paragraphText) => ({ type: 'text', paragraphText }));
            const generatedContentData = transformSourceOfFundsData(generatedContent.fundingsAndInvestments);
            const contentData: JwWordTableInsertData = {
              type: 'table',
              tableTitle: 'Funds Invested:',
              columnTitles: [''],
              generatedContentData,
            };
            return [...contentText, contentData];
          }),
        );

      // USE OF PROCEEDS
      case JwWordBusinessPlanTable.UseOfProceeds:
        return this.generatedComputedDataService.getTableData(businessPlanId).pipe(
          map(({ generatedContent }) => {
            const generatedContentData = transformUseOfProceedsData(generatedContent.fundingsAndInvestments);
            const contentData: JwWordTableInsertData = {
              type: 'table',
              tableTitle: 'Expenses Made:',
              columnTitles: [''],
              generatedContentData,
            };
            return [contentData];
          }),
        );

      // CONCLUSION AUTO-GENERATED TEXT
      case JwWordBusinessPlanTable.Conclusion:
        return this.generatedComputedDataService.getTableData(businessPlanId).pipe(
          map(({ generatedContent, yearLabels, businessPlan }) => {
            const companyName = businessPlan.name;
            const firstApplicantName = businessPlan.applicants?.[0]?.name ?? '';
            const personnelData = generatedContent.personnel;
            const totalSales = (generatedContent.salesForecast.total?.year ?? []).map((y) => y.totalSales as number);
            const paragraphText = generateConclusionText(personnelData, totalSales, companyName, firstApplicantName, yearLabels, this.locale);
            const content: JwWordTextInsertData = { type: 'text', paragraphText };
            return [content];
          }),
        );

      // EXECUTIVE SUMMARY AUTO-GENERATED TEXT
      case JwWordBusinessPlanTable.ExecutiveSummary:
        return this.generatedComputedDataService.getTableData(businessPlanId).pipe(
          map(({ generatedContent, yearLabels, businessPlan }) => {
            const companyName = businessPlan.name;
            const personnelData = generatedContent.personnel;
            const firstYearPositionsWithEmployees = (personnelData.positions?.position ?? [])
              .filter(({ year }) => year?.[0].numberOfEmployees ?? 0 > 0)
              .map((p) => ({ name: p['@name'], nEmployees: p.year?.[0].numberOfEmployees ?? 0 }));
            if (!firstYearPositionsWithEmployees.length) {
              this.displayNoFirstYearPositionsWithEmployeesWarning();
              return [];
            }
            const hasOutsourcingExpenses = _.some(generatedContent.expensesDetail.expenses?.expense, (expense) => expense?.isOutsourcingExpense);
            if (!hasOutsourcingExpenses) {
              this.displayNoOutsourcingExpensesWarning();
              return [];
            }
            const outsourcingExpenseNames = (generatedContent.expensesDetail.expenses?.expense ?? []).map((expense) => expense['@name']);
            const totalSales = (generatedContent.salesForecast.total?.year ?? []).map((y) => y.totalSales as number);
            const paragraphTexts = generateExecutiveSummaryText(
              personnelData,
              totalSales,
              outsourcingExpenseNames,
              firstYearPositionsWithEmployees,
              companyName,
              yearLabels,
              this.locale,
            );
            const content: JwWordTextInsertData[] = paragraphTexts.map((paragraphText) => ({ type: 'text', paragraphText }));
            return [...content];
          }),
        );

      default:
        console.error(`Content Control Generation not implemented for this table tag : ${tableTag}`);
        return of();
    }
  }

  getBusinessPlanListByProjectId(projectId: number) {
    return this.businessPlanProjectDataAccessService.getBusinessPlanListByProjectId(projectId);
  }
}
