import { $contextBusinessPlanVersions } from '@joorney/business-plan-shared-frontend-context-store';
import { jwWordMsOfficeContentControlExtractTagInfos } from '@joorney/ms-office-jwriter-word-office-api-data-access';
import { TABLE_NAMES } from '@joorney/ms-office-jwriter-word-sections-domain';
import { createSelector } from '@ngrx/store';
import _ from 'lodash';
import { dataSyncFeature } from './data-sync.reducer';

export const { selectSelectedContentControlId: $syncDataSelectedContentControlId, selectContentControls: $syncDataContentControls } = dataSyncFeature;

export const $syncDataContentControlList = createSelector($syncDataContentControls, (contentControls) => Object.values(contentControls));

export const $syncDataEditableContentControls = createSelector($syncDataContentControlList, (contentControls) => contentControls.filter((cc) => !cc.cannotEdit));

export const $syncDataGetContentControlById = (id: number) => createSelector($syncDataContentControls, (contentControls) => contentControls[id]);

export const $syncDataGetEditableContentControlById = (id: number) => createSelector($syncDataGetContentControlById(id), (cc) => (!cc?.cannotEdit ? cc : undefined));

export const $syncDataSelectedContentControlData = createSelector($syncDataContentControls, $syncDataSelectedContentControlId, (contentControls, selectedCcId) => {
  if (selectedCcId === null) {
    return undefined;
  }
  const contentControl = contentControls[selectedCcId];
  if (contentControl === undefined) {
    return undefined;
  }
  const { bpId, tableTag } = jwWordMsOfficeContentControlExtractTagInfos(contentControl.tag);
  return { ...contentControl, bpId, tableName: TABLE_NAMES[tableTag] };
});

export const $syncDataNoContentControls = createSelector($syncDataContentControls, (contentControls) => _.isEmpty(contentControls));

export const $syncDataContentControlsAreFromSelectedBp = createSelector($syncDataContentControlList, $contextBusinessPlanVersions, (contentControls, businessPlanVersions) => {
  if (_.isEmpty(contentControls) || _.isEmpty(businessPlanVersions)) {
    return false;
  }
  const [{ tag }] = contentControls;
  const { bpId } = jwWordMsOfficeContentControlExtractTagInfos(tag);
  return businessPlanVersions.map((bp) => bp.id).includes(bpId);
});

export const $syncDataSelectedContentControlsIsFromSelectedBp = createSelector(
  $syncDataSelectedContentControlData,
  $contextBusinessPlanVersions,
  (selectedContentControl, businessPlanVersions) => {
    if (selectedContentControl === undefined || _.isEmpty(businessPlanVersions)) {
      return false;
    }
    const { bpId } = selectedContentControl;
    return businessPlanVersions.map((bp) => bp.id).includes(bpId);
  },
);

export const $syncDataAllContentControlsEditable = createSelector($syncDataEditableContentControls, (editableContentControls) => !_.isEmpty(editableContentControls));
