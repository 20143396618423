import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import * as xml2js from 'xml2js';
import { contentControlLocation } from './msoffice-content-control.utils';

export const MSOfficeChartTitleTag = 'JW_TITLE';
export const MSOfficeChartYearsTag = 'JW_YEARS';

export type MSOfficePAndLChartElementTag = 'JW_DATA_NET_PROFIT' | 'JW_DATA_GROSS_MARGIN' | 'JW_DATA_SALES';
export type MSOfficeBreakEvenChartElementTag = 'JW_DATA_REVENUES' | 'JW_DATA_FIXED_COSTS' | 'JW_DATA_TOTAL_COSTS';
export type MSOfficeTaxPaidByYearElementTag = 'JW_DATA_PAYROLL_TAX' | 'JW_DATA_NET_INCOME_TAX' | 'JW_DATA_TOTAL_TAX';
export type MSOfficeChartFileName = 'ProfitAndLossChart' | 'ProfitAndLossChart_Light' | 'BreakEvenAnalysisChart' | 'TaxPaidByYearChart';
export type MSOfficeOrgChartFileName = 'OrgChartInline';

export type MSOfficePAndLChartElementsData = {
  [key in MSOfficePAndLChartElementTag]: number[];
};
export type MSOfficeBreakEvenChartElementsData = {
  [key in MSOfficeBreakEvenChartElementTag]: number[];
};
export type MSOfficeTaxPaidByYearChartElementsData = {
  [key in MSOfficeTaxPaidByYearElementTag]: number[];
};

export interface JwWordChartInsertData {
  type: 'chart';
  filename: MSOfficeChartFileName;
  title: string;
  yearLabels: (string | number)[];
  elements: MSOfficePAndLChartElementsData | MSOfficeBreakEvenChartElementsData | MSOfficeTaxPaidByYearChartElementsData;
}
export interface JwWordOrgChartInsertData {
  type: 'org-chart';
  filename: MSOfficeOrgChartFileName;
  positionsNames: string[];
}

const jwWordMsOfficeChartCreateOrgChartItem = (positionTitles: string[]) => {
  return positionTitles.map((positionTitle) => ({
    'dgm:pt': {
      $: { modelId: `{${uuidv4()}}` },
      'dgm:prSet': { $: { phldrT: '[Text]' } },
      'dgm:spPr': '',
      'dgm:t': {
        'a:bodyPr': '',
        'a:lstStyle': '',
        'a:p': {
          'a:r': {
            'a:rPr': { $: { lang: 'en-US' } },
            'a:t': positionTitle,
          },
        },
      },
    },
  }));
};
const jwWordMsOfficeChartCreateObjectValue = (values: number[], yearLabels: (string | number)[]) => {
  const newValues = values ?? Array(yearLabels.length).fill(0);
  return {
    'c:numLit': {
      'c:formatCode': '"$"#,##0;"($"#,##0);"$0"',
      'c:ptCount': { $: { val: newValues.length } },
      'c:pt': newValues.map((v, idx) => ({
        $: { idx },
        'c:v': v,
      })),
    },
  };
};

const jwWordMsOfficeChartCreateObjectYearLabels = (yearsLabel: (string | number)[]) => {
  return {
    'c:strLit': {
      'c:ptCount': { $: { val: yearsLabel?.length } },
      'c:pt': yearsLabel?.map((v, idx) => ({
        $: { idx },
        'c:v': v,
      })),
    },
  };
};

const jwWordMsOfficeOrgChartReplaceInChart = (originalXml: string, positionsNames: string[]) => {
  const builder = new xml2js.Builder({ headless: true });
  const objectValues = jwWordMsOfficeChartCreateOrgChartItem(positionsNames);
  const xmlValues = objectValues.map((objectValue) => builder.buildObject(objectValue));
  return originalXml.replace(/\$\{JW_CONTENT\}/gm, _.join(xmlValues, '\n'));
};

const jwWordMsOfficeChartReplaceInChart = (
  originalXml: string,
  title: string,
  yearLabels: (string | number)[],
  elements: MSOfficePAndLChartElementsData | MSOfficeBreakEvenChartElementsData | MSOfficeTaxPaidByYearChartElementsData,
) => {
  const builder = new xml2js.Builder({ headless: true });
  const enhancedXml = _.reduce(
    elements,
    (xml, values, index) => {
      const objectValues = jwWordMsOfficeChartCreateObjectValue(values, yearLabels);
      const xmlValues = builder.buildObject(objectValues);
      return xml.replace(index, xmlValues);
    },
    originalXml,
  );

  const objectYearLabels = jwWordMsOfficeChartCreateObjectYearLabels(yearLabels);
  const xmlYearLabels = builder.buildObject(objectYearLabels);

  return enhancedXml.replace(/\$\{JW_TITLE\}/gm, title).replace(/\$\{JW_YEARS\}/gm, xmlYearLabels);
};

export const jwWordMsOfficeChartInsertContentControl = async (
  context: Word.RequestContext,
  contentControl: Word.ContentControl,
  { filename, title, yearLabels, elements }: JwWordChartInsertData,
): Promise<void> => {
  const myOOXMLRequest = new XMLHttpRequest();
  myOOXMLRequest.open('GET', window.location.origin + `/assets/office-api-data-access/${filename}.xml`, false);
  myOOXMLRequest.send();
  if (myOOXMLRequest.status === 200) {
    const originalXML = myOOXMLRequest.responseText;
    const titleSanitized = _.escape(title);
    const enhancedXML = jwWordMsOfficeChartReplaceInChart(originalXML, titleSanitized, yearLabels, elements);
    contentControl.insertOoxml(enhancedXML, contentControlLocation.After);
    await context.sync();
  }
};

export const jwWordMsOfficeOrgChartInsertContentControl = async (
  context: Word.RequestContext,
  contentControl: Word.ContentControl,
  { filename, positionsNames }: JwWordOrgChartInsertData,
): Promise<void> => {
  const myOOXMLRequest = new XMLHttpRequest();
  myOOXMLRequest.open('GET', window.location.origin + `/assets/office-api-data-access/${filename}.xml`, false);
  myOOXMLRequest.send();
  if (myOOXMLRequest.status === 200) {
    const originalXML = myOOXMLRequest.responseText;
    const enhancedXML = jwWordMsOfficeOrgChartReplaceInChart(originalXML, positionsNames);
    contentControl.insertOoxml(enhancedXML, contentControlLocation.After);
    await context.sync();
  }
};
