import { formatNumber } from '@angular/common';
import { GeneratedContent } from '@joorney/computation-shared-frontend-generated-computed-data-data-access';
import {
  GeneratedContentBreakEven,
  GeneratedContentBreakEvenTable,
  GeneratedContentData,
  GeneratedContentSalesForecast,
  JwTableRowClass,
} from '@joorney/computation-shared-frontend-sections-domain';
import { BusinessPlanBreakEvenType, SalesForecastProductType } from '@joorney/shell-shared-jwriter-core-api-data-access';

export const showMonthlyUnitsBreakEven = (table: GeneratedContentBreakEvenTable) => {
  return table.monthlyUnitsBreakEven !== undefined && Math.round(table.monthlyUnitsBreakEven) !== 0;
};

export const transformBreakEvenData = (data: GeneratedContentBreakEven): GeneratedContentData[] => {
  let datasource: GeneratedContentData[];

  if (data['@type'] === BusinessPlanBreakEvenType.UNIT) {
    datasource = [
      {
        label: 'Monthly Units Break-Even',
        values: [data.table.monthlyUnitsBreakEven ?? 0],
        hide: !showMonthlyUnitsBreakEven(data.table),
      },
      {
        label: 'Monthly Revenue Break-Even',
        values: [data.table.monthlyRevenueBreakEven ?? 0],
        isCurrency: true,
        withDigits: true,
      },
      {
        label: 'Assumptions',
        styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableDivider],
        values: [null],
      },
      {
        label: 'Average Per-Unit Revenue',
        values: [data.table.averagePerUnitRevenue ?? 0],
        isCurrency: true,
        withDigits: true,
      },
      {
        label: 'Average Per-Unit Variable Cost',
        values: [data.table.averagePerUnitVariableCost ?? 0],
        isCurrency: true,
        withDigits: true,
      },
      {
        label: 'Estimated Monthly Fixed Cost',
        values: [data.table.estimatedMonthlyFixedCost ?? 0],
        isCurrency: true,
        withDigits: true,
      },
    ];
  } else {
    datasource = [
      {
        label: 'Daily Revenue Break-Even',
        values: [data.table.dailyRevenueBreakEven ?? 0],
        isCurrency: true,
        noDigits: true,
      },
      {
        label: 'Monthly Revenue Break-Even',
        values: [data.table.monthlyRevenueBreakEven ?? 0],
        isCurrency: true,
        noDigits: true,
      },
      {
        label: 'Annual Revenue Break-Even',
        values: [data.table.annualRevenueBreakEven ?? 0],
        isCurrency: true,
        noDigits: true,
      },
      {
        label: 'Assumptions',
        styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableDivider],
        values: [null],
      },
      {
        label: 'Estimated Daily Fixed Cost',
        values: [data.table.estimatedDailyFixedCost ?? 0],
        isCurrency: true,
        noDigits: true,
      },
      {
        label: 'Estimated Month Fixed Cost',
        values: [data.table.estimatedMonthlyFixedCost ?? 0],
        isCurrency: true,
        noDigits: true,
      },
      {
        label: 'Estimated Annual Fixed Cost',
        values: [data.table.estimatedAnnualFixedCost ?? 0],
        isCurrency: true,
        noDigits: true,
      },
    ];
  }
  return datasource.filter(({ values }) => values?.some((v) => v !== 0));
};

export const transformBreakEvenChartData = (data: GeneratedContentBreakEven, locale: string) => {
  return {
    categoriesX: data.chart.categoriesX.X.map((value) => formatNumber(value, locale)),
    series: {
      JW_DATA_REVENUES: data.chart.series.serieRevenues.Y,
      JW_DATA_FIXED_COSTS: data.chart.series.serieFixedCosts.Y,
      JW_DATA_TOTAL_COSTS: data.chart.series.serieTotalCosts.Y,
    },
  };
};

export const breakEvenAnalysisTextBeforeTable = (): string => {
  return 'The break-even point refers to the revenues needed to cover the Company\'s total amount of fixed and variable expenses during a specified period of time.';
};

export const breakEvenAnalysisTextAfterTable = (generatedContent: GeneratedContent, locale: string): string[] => {
  const amount = generatedContent.breakEven.table.monthlyRevenueBreakEven ? Math.round(generatedContent.breakEven.table.monthlyRevenueBreakEven) : 0;
  const amountEnglishFormat = formatNumber(amount, locale, '1.0-0');
  const finallyText =
    'The Company will engage in a significant number of activities aimed at attracting and retaining customers. The Company is expected to reach its break-even point promptly in each period.';
  if (generatedContent.breakEven['@type'] === BusinessPlanBreakEvenType.VALUE) {
    return [`The Company’s monthly revenue break-even point will amount to $${amountEnglishFormat} in Year 1.`, finallyText];
  }

  const monthlyUnitsBreakEven = Math.round(Math.abs(generatedContent.breakEven.table.monthlyUnitsBreakEven ?? 0) ?? 0);
  const type = checkTypeOfSales(generatedContent.salesForecast);
  return [`In Year 1, the break-even point of $${amountEnglishFormat} per month represents an average of ${monthlyUnitsBreakEven} ${type} provided per month.`, finallyText];
};

const checkTypeOfSales = (salesForecast: GeneratedContentSalesForecast): string => {
  if (salesForecast.products?.product?.every((product) => product.type === SalesForecastProductType.SERVICE)) {
    return 'services';
  }
  if (salesForecast.products?.product?.every((product) => product.type === SalesForecastProductType.PRODUCT)) {
    return 'products';
  }
  return 'products and services';
};
