@if (
  {
    countryOptions: countryOptions$ | async,
    categoryOptions: categoryOptions$ | async
  };
  as vm
) {
  <jw-stack-layout-ui>
    <ng-container title>{{ title }}</ng-container>
    <form [formGroup]="form" class="flex flex-column gap-3">
      <div class="flex gap-3 align-items-end">
        <div class="flex-auto">
          <label>Name</label>
          <input class="w-full" formControlName="name" type="text" pInputText />
        </div>
        @if (!isNew) {
          <p-button
            [rounded]="true"
            [text]="true"
            (click)="delete()"
            icon="pi pi-trash"
            class="flex-0"
          />
        }
      </div>
      <div>
        <div class="flex-auto">
          <label>Link</label>
          <input class="w-full" formControlName="link" type="text" pInputText />
        </div>
      </div>
      <div>
        <label>Country</label>
        <p-dropdown
          formControlName="countryCode"
          [options]="vm.countryOptions ?? []"
          optionLabel="name"
          optionValue="code"
          placeholder="Select a country"
          styleClass="w-full"
        />
      </div>
      <div>
        <label>Category</label>
        <p-dropdown
          formControlName="categoryCountryId"
          [options]="vm.categoryOptions ?? []"
          optionLabel="name"
          optionValue="id"
          placeholder="Select a category"
          styleClass="w-full"
        />
      </div>
    </form>
    <ng-container actions>
      <button
        class="p-button-rounded"
        [disabled]="!form.valid"
        (click)="save(true)"
        pButton
        pRipple
        label="Save"
        icon="pi pi-save"
      ></button>
      @if (isNew) {
        <button
          class="p-button-rounded"
          [disabled]="!form.valid"
          (click)="save(false)"
          pButton
          pRipple
          label="Save & Add"
          icon="pi pi-save"
        ></button>
      }
      <button
        class="p-button-outlined p-button-rounded"
        (click)="cancel()"
        pButton
        pRipple
        label="Cancel"
      ></button>
    </ng-container>
  </jw-stack-layout-ui>
}
