import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BusinessPlanProjectSearchInput, REFRESH_BP_PROJECT_BY_ID, SearchInputFeatureComponent } from '@joorney/business-plan-jwriter-word-search-input-feature';
import {
  $contextCurrentBusinessPlanId,
  $contextCurrentBusinessPlanName,
  $contextCurrentBusinessPlanParentId,
  $contextCurrentBusinessPlanYearLabels,
  contextActions,
} from '@joorney/business-plan-shared-frontend-context-store';
import { JwWordBusinessPlanSectionName, JwWordBusinessPlanTable, JwWordTable, JwWordTableSection, getBusinessPlanSections } from '@joorney/ms-office-jwriter-word-sections-domain';
import { toastActions } from '@joorney/shell-shared-frontend-toast-store';
import { BusinessPlanByProjectDTO } from '@joorney/shell-shared-jwriter-core-api-data-access';
import { ObservableComponentClass } from '@joorney/utils-shared-frontend-ng-component-utils';
import { Store, createSelector } from '@ngrx/store';
import * as _ from 'lodash';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { catchError, combineLatest, distinctUntilChanged, filter, finalize, map, of, switchMap, take, takeUntil, tap } from 'rxjs';
import { ComputationPageFeatureService } from './computation-page-feature.service';
import { Tendency, tendenciesCalculationFromValues } from './computation-page-feature.utils';

const NO_CURSOR_ERROR_MESSAGE = 'Error: You must place your cursor on the document';
const NO_PROJECT_ERROR_MESSAGE = 'You must select a project';
const DISPLAY_YEAR_INPUT_TAG_WHITELIST = [JwWordBusinessPlanTable.PersonnelPlanOrgChart];

export const $currentBusinessPlanYearLabelOptions = createSelector($contextCurrentBusinessPlanYearLabels, (years) => years.map((name, value) => ({ name, value })));

type BusinessPlanInfo = { id: number; name: string; bpInitialId: number };
export const contextBusinessPlanInfo = createSelector(
  $contextCurrentBusinessPlanId,
  $contextCurrentBusinessPlanName,
  $contextCurrentBusinessPlanParentId,
  (id, name, bpInitialId): BusinessPlanInfo | null => {
    if (id === undefined || name === undefined || bpInitialId === undefined) {
      return null;
    }
    return { id, name, bpInitialId };
  },
);

@Component({
  selector: 'jw-word-computation-page-feature',
  standalone: true,
  imports: [CommonModule, DropdownModule, ButtonModule, ReactiveFormsModule, ProgressSpinnerModule, SearchInputFeatureComponent],
  providers: [ComputationPageFeatureService],
  templateUrl: './computation-page-feature.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComputationPageFeatureComponent extends ObservableComponentClass {
  loading = false;
  showErrorMessage = false;

  readonly noCursorErrorMessage = NO_CURSOR_ERROR_MESSAGE;
  readonly noProjectErrorMessage = NO_PROJECT_ERROR_MESSAGE;

  readonly formGroup = this.generateForm();
  readonly businessPlanVersions$ = this.getBusinessPlanVersion$();
  readonly businessPlanSections$ = this.getBusinessPlanSections$();
  readonly businessPlanTables$ = this.getBusinessPlanTables$();
  readonly displayYearInput$ = this.isYearInputDisplayed$();
  readonly yearLabelOptions$ = this.store.select($currentBusinessPlanYearLabelOptions);

  constructor(
    private computationPageFeatureService: ComputationPageFeatureService,
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store,
  ) {
    super();
    this.initWithContextBusinessPlan();
  }

  private generateForm() {
    return new FormGroup({
      businessPlanProject: new FormControl<BusinessPlanProjectSearchInput | null>(null, [Validators.required]),
      businessPlanVersion: new FormControl<BusinessPlanByProjectDTO | null>(null, [Validators.required]),
      businessPlanSection: new FormControl<JwWordTableSection | null>(null, [Validators.required]),
      businessPlanTable: new FormControl<JwWordTable | null>(null, [Validators.required]),
      yearIndex: new FormControl<number | null>(null),
    });
  }

  private getBusinessPlanVersion$() {
    return combineLatest({
      refresh: REFRESH_BP_PROJECT_BY_ID,
      projectItem: this.formGroup.controls.businessPlanProject.valueChanges.pipe(distinctUntilChanged((p, c) => p?.id === c?.id)),
    }).pipe(
      // If we don't receive a projectItem we need to return an empty array in order to clean the other selectors
      switchMap(({ projectItem }) => (projectItem?.id ? this.computationPageFeatureService.getBusinessPlanListByProjectId(projectItem?.id) : of<BusinessPlanByProjectDTO[]>([]))),
      tap((list) => {
        const defaultVersion = list[list.length - 1] ?? null;
        const matchingVersion = list.find(({ id }) => id === this.formGroup.value.businessPlanVersion?.id);
        this.formGroup.patchValue({
          businessPlanVersion: matchingVersion === undefined ? defaultVersion : matchingVersion,
          businessPlanSection: null,
          businessPlanTable: null,
          yearIndex: null,
        });
      }),
    );
  }

  private getBusinessPlanSections$() {
    return this.formGroup.controls.businessPlanVersion.valueChanges.pipe(
      distinctUntilChanged((p, c) => p?.id === c?.id),
      tap((businessPlan) => {
        if (businessPlan?.id !== undefined) {
          this.store.dispatch(contextActions.initWithBusinessPlanId({ businessPlanId: businessPlan.id }));
        } else {
          this.store.dispatch(contextActions.currentBusinessPlanUnselected());
        }
        this.formGroup.patchValue({ businessPlanSection: null, businessPlanTable: null, yearIndex: null });
      }),
      map((businessPlan) => (businessPlan !== null ? getBusinessPlanSections(businessPlan.featureConfiguration, businessPlan.salesForecastType) : [])),
    );
  }

  private getBusinessPlanTables$() {
    return this.formGroup.controls.businessPlanSection.valueChanges.pipe(
      switchMap((section) => {
        if (section === null) {
          return of([]);
        }
        const bpId = this.formGroup.controls.businessPlanVersion.value?.id;
        if (bpId === undefined) {
          return of(section.tables);
        }

        switch (section.tag) {
          case JwWordBusinessPlanSectionName.Feasibility:
            return this.validateProfitAndLossDecrease$(bpId, section.tables);
          case JwWordBusinessPlanSectionName.PersonnelPlan:
            return this.validateApplicantPosition$(bpId, section.tables);
          case JwWordBusinessPlanSectionName.ExecutiveSummary:
            return this.validateOutsourcingExpenses$(bpId, section.tables);
          default:
            return of(section.tables);
        }
      }),
      tap((tables) => {
        const firstElementEnabled = tables.find(({ disabled }) => !disabled);
        this.formGroup.patchValue({ businessPlanTable: firstElementEnabled === undefined ? null : firstElementEnabled });
      }),
    );
  }

  private isYearInputDisplayed$() {
    return this.formGroup.controls.businessPlanTable.valueChanges.pipe(
      map((table) => (table?.tag === undefined ? false : DISPLAY_YEAR_INPUT_TAG_WHITELIST.includes(table.tag))),
      tap((isDisplayed) => this.updateYearIndexValidators(isDisplayed)),
    );
  }

  private initWithContextBusinessPlan() {
    this.store
      .select(contextBusinessPlanInfo)
      .pipe(
        filter((bp): bp is BusinessPlanInfo => bp !== null && bp.bpInitialId !== this.formGroup.value.businessPlanProject?.id),
        take(1), // We only want to initialize the form with the context value once
        tap(({ id, bpInitialId, name }) =>
          this.formGroup.patchValue(
            {
              businessPlanProject: { id: bpInitialId, lastBPCompanyName: name } as unknown as BusinessPlanProjectSearchInput,
              businessPlanVersion: { id } as unknown as BusinessPlanByProjectDTO,
            },
            { emitEvent: false },
          ),
        ),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  private updateYearIndexValidators(isEnabled: boolean) {
    if (isEnabled) {
      this.formGroup.controls.yearIndex.addValidators(Validators.required);
    } else {
      this.formGroup.controls.yearIndex.removeValidators(Validators.required);
    }
    this.formGroup.controls.yearIndex.updateValueAndValidity();
  }

  private validateProfitAndLossDecrease$(bpId: number, tables: JwWordTable[]) {
    return this.computationPageFeatureService.generateTablesData(bpId).pipe(
      map(({ generatedContent, yearLabels }) => {
        const values = (generatedContent.salesForecast.total?.year ?? []).map((y, i) => Number(generatedContent.profitAndLoss.year?.[i].netProfit) / Number(y.totalSales));
        const tendencies = tendenciesCalculationFromValues(values);
        const firstYearDecreaseTendency = tendencies.filter(({ tendency }) => tendency === Tendency.Decrease || tendency === Tendency.DecreaseContD)?.[0];
        if (firstYearDecreaseTendency === undefined) {
          return tables;
        }

        const yearsParams = { currYear: yearLabels[firstYearDecreaseTendency.to], prevYear: yearLabels[firstYearDecreaseTendency.from] };
        this.computationPageFeatureService.displayProfitAndLossDecreaseWarning(yearsParams);
        return tables.map((table) => (table.tag === JwWordBusinessPlanTable.CostBenefitAnalysis ? { ...table, disabled: true } : table));
      }),
    );
  }

  private validateApplicantPosition$(bpId: number, tables: JwWordTable[]) {
    return this.computationPageFeatureService.personnelPlanPositionsData(bpId).pipe(
      map((employeesInPosition) => {
        if (!employeesInPosition.length) {
          return tables.map((table) => (table.tag === JwWordBusinessPlanTable.ApplicantPosition ? { ...table, disabled: true } : table));
        }
        const noPositionsWithApplicants = _.every(employeesInPosition, ({ employees }) => _.every(employees, (employee) => !employee.isApplicant));
        if (noPositionsWithApplicants) {
          return tables.map((table) => (table.tag === JwWordBusinessPlanTable.ApplicantPosition ? { ...table, disabled: true } : table));
        }
        return tables;
      }),
    );
  }

  private validateOutsourcingExpenses$(bpId: number, tables: JwWordTable[]) {
    return this.computationPageFeatureService.generateTablesData(bpId).pipe(
      map(({ generatedContent }) => {
        const hasFirstYearPositionsWithEmployees = _.some(generatedContent.personnel.positions?.position, (position) => position.year?.[0].numberOfEmployees ?? 0 > 0);
        if (!hasFirstYearPositionsWithEmployees) {
          return tables.map((table) => (table.tag === JwWordBusinessPlanTable.ExecutiveSummary ? { ...table, disabled: true } : table));
        }
        const hasOutsourcingExpenses = _.some(generatedContent.expensesDetail.expenses?.expense, (expense) => expense?.isOutsourcingExpense);
        if (!hasOutsourcingExpenses) {
          return tables.map((table) => (table.tag === JwWordBusinessPlanTable.ExecutiveSummary ? { ...table, disabled: true } : table));
        }
        return tables;
      }),
    );
  }

  insertTable() {
    this.computationPageFeatureService
      .isSelectionInContentControl()
      .pipe(
        map((isSelectionInContentControl) => !isSelectionInContentControl),
        tap((canInsert) => {
          if (canInsert) {
            return;
          }
          this.store.dispatch(
            toastActions.displayWarningMessage({
              messageText: 'In order to insert a new element you must place your cursor outside the selected content control',
              options: { manualDismiss: true },
            }),
          );
        }),
        filter((canInsert) => canInsert),
        tap(() => (this.loading = true)),
        switchMap(() => {
          const businessPlan = this.formGroup.controls.businessPlanVersion.value;
          const selectedTable = this.formGroup.controls.businessPlanTable.value?.tag;
          const yearIndex = this.formGroup.controls.yearIndex.value;
          if (businessPlan === null || selectedTable === undefined) {
            return of(null);
          }
          return this.computationPageFeatureService.insertMsOfficeTableContentControl(businessPlan, selectedTable, yearIndex);
        }),
        tap(() => (this.showErrorMessage = false)),
        catchError((error: unknown) => {
          this.showErrorMessage = true;
          console.error(error);
          return of(null);
        }),
        finalize(() => {
          this.loading = false;
          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }
}
