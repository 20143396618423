export enum MsOfficeDocumentMetadataKey {
  BusinessPlanProjectId = 'JWRITER_PROJECT_ID',
  BusinessPlanVersionId = 'JWRITER_VERSION_ID',
}

export const jwWordMsOfficeMetadataSet = (metadataKey: MsOfficeDocumentMetadataKey, value: string | number | boolean | undefined | null) => {
  if (value === null || value === undefined) {
    Office.context.document.settings.remove(metadataKey);
  } else {
    Office.context.document.settings.set(metadataKey, value);
  }
  Office.context.document.settings.saveAsync();
};

export const jwWordMsOfficeMetadataGet = <TReturn = string>(metadataKey: MsOfficeDocumentMetadataKey) => Office.context.document.settings.get(metadataKey) as TReturn | null;
